@import './var';
@import './reset';

:global(.side-menu) {
  z-index: 99;
}

:global(.content-page) {
  //width: 100%;
  //height: 100%;
}

:global(.actionPage) {
  margin-bottom: 62px;
}

:global(.actionBar) {
  text-align: right;
  background-color: #fff;
  border-top: 8px solid #f0f2f5;
  padding: 10px 20px;
  position: fixed;
  width: calc(100%);
  bottom: 0;
  left: 0;
  display: block;
}

:global(.but-card) {
  :global(.ant-card-head) {
    border-bottom: none;
    padding: 0 16px;

    :global(.ant-card-head-title) {
      &:before {
        content: ' ';
        display: inline-block;
        width: 4px;
        height: 18px;
        background-color: @primary-color;
        vertical-align: text-top;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
  }

  :global(.ant-card-body) {
    padding-top: 8px;
  }
}

:global(.but-title) {
  font-size: 16px;
  font-weight: 500;
  color: @break-color2;
  position: relative;
  padding-left: 12px;
  margin-bottom: 16px;

  &:before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 14px;
    background-color: @primary-color;
    border-radius: 0 2px 2px 0;
    position: absolute;
    left: 0px;
    top: 4px;
  }
}

:global(.text-ellipsis) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.space-padding {
  padding-right: 32px !important;
  width: 260px;
  text-align: right;
}

:global {
  .back-popover {
    .ant-popover-inner {
      border-radius: 8px;
    }

    .ant-popover-arrow-content {
      background: @break-color3  !important;

      &::before {
        background: none !important;
      }
    }
  }
  * {

    &::-webkit-scrollbar{
      width: 6px;
      height: 6px;
      background-color: rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb{
      background-color: #ccc;
      border-radius: 10px;
    }
  }
}

:global {
  .confirm_danger_icon_color{
    color: #ff4d4f !important;
  }
}
