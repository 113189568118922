@import '@/style/mixin.less';

.stop-watch {
  display: flex;
  align-items: center;
  height: 36px;
  background: #FFFFFF;
  border-radius: 18px;
  justify-content: space-between;

  .watch-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    // background-color: #C2E9FC;
    background-image: url('~@/assets/images/common/time_icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .time {
    font-size: 14px;
    color: #0b2145d9;
    margin-left: 10px;

    :global(.ant-statistic-content) {
      display: flex;
      align-items: center;
    }
  }

  .watch-pause {
    font-size: 14px;
    color: #0b2145a6;
    margin-left: 8px;
    cursor: pointer;
  }

  .watch-reset {
    font-size: 14px;
    color: #0b2145a6;
    margin: 0 8px;
    cursor: pointer;
  }

  .watch-close {
    font-size: 14px;
    color: #0b2145a6;
    cursor: pointer;
  }

  .watch-timekeeping {
    font-size: 14px;
    color: #0b2145a6;
    padding: 0 16px 0 10px;
  }
}

.close-watch {
  width: 225px;
  padding-right: 16px;
}