// 预览讲稿-调整字体
.addFontSize(@val) {
  [data-size='1'] & {
    font-size: @val * 1;
  }

  [data-size='2'] & {
    font-size: @val * 1.2;
  }

  [data-size='3'] & {
    font-size: @val * 1.3;
  }
}

.scroll-bar() {

  /* 高宽分别对应横竖滚动条的尺寸 */
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
    height: 1px;
  }

  /* 滚动条里面轨道 */
  &::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 20%);
    border-radius: 10px;
  }

  /* 滚动条里面轨道 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}