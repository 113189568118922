@import '@/style/mixin.less';

.speech-issue {
  width: 260px;
  height: calc(100vh - 72px);
  flex-shrink: 0;
  margin-left: 10px;
  background: #ffffff;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.1);

  .issue-header {
    padding: 16px 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 4px;
      background: #4288FF;
      position: absolute;
      left: 0;
      top: 16px;
      bottom: 16px;
    }

    .issue {
      background-image: linear-gradient(227deg, #83ddf8 0%, #4c83ee 90%);
      border-radius: 0 100px 100px 0;
      font-weight: 500;
      font-size: 14px;
      .addFontSize(14px);
      color: #ffffff;
      padding: 10px 42px 10px 18px;
    }

    .issue-close {
      width: 30px;
      height: 30px;
      color: rgba(11, 33, 69, 0.45);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: right;
      position: relative;
      z-index: 999;
    }
  }

  .issue-container {
    padding: 0 12px;

    .issue-item {
      padding: 12px;
      background: #f6f8fb;
      border-radius: 4px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .item-header {
        display: flex;
        margin-bottom: 10px;

        .header-icon {
          background: #4288FF;
          border: 1px solid #a3d0fd;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          .addFontSize(14px);
          color: #ffffff;
          letter-spacing: 0;
          line-height: 16px;
          padding: 4px 8px;
        }
      }

      .container {
        font-weight: 500;
        font-size: 14px;
        .addFontSize(14px);
        color: rgba(0, 0, 0, 0.75);
        letter-spacing: 0;
        text-align: justify;
        line-height: 24px;
      }
    }
  }
}

.issue-open {
  position: fixed;
  top: 140px;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 60px;
  background: #e6f1fc;
  border: 1px solid #4288FF;
  border-radius: 4px 0 0 4px;
  font-weight: 500;
  font-size: 14px;
  color: #4288FF;
  letter-spacing: 0;
  line-height: 16px;
  padding: 8px 6px;
  z-index: 999;
}