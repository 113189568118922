.layout {
  height: 100vh;

  .sider {
    background: #ECF2F9;

    .logo {
      height: 60px;
      text-align: center;
      padding-top: 19px;
      margin-bottom: 20px;

      >div {
        display: inline-block;

        img {
          height: 20px;
          vertical-align: text-bottom;
          transition: height 0.3s;
        }

        span {
          margin-left: 8px;
          font-size: 20px;
          font-weight: 500;
          color: #303030;
        }
      }
    }

    :global {
      .ant-menu {
        background-color: #ECF2F9;
        border-right: none;

        .ant-menu-item {
          border-radius: 0 100px 100px 0;
          margin-left: 0;
          &-selected {
            color: #fff;
            background-image: linear-gradient(227deg, #83DDF8 0%, #4C83EE 90%);
            position: relative;

            &:after {
              position: absolute;
              left: 0;
              top: 0;
              height: 100px;
              width: 4px;
              background: #0857DE;
            }
          }
        }
        .ant-menu-submenu-title{
          margin-left: 0;

        }
        .ant-menu-sub.ant-menu-inline {
          background: none;
        }
      }
    }
  }

  .header {
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 8px;

    //box-shadow: 0 1px 4px 0 #0015291f;
    .pageInfo {
      flex: 0 0 260px;

      .collapsedBtn {
        height: 54px;
        width: 54px;
        vertical-align: middle;
      }
    }

    .toolbar {
      flex: 1;
      text-align: right;

      .fullBtn {
        :global {
          .anticon {
            border: 1px solid rgba(11, 33, 69, 0.85);
            border-radius: 2px;
            color: rgba(11, 33, 69, 0.85);
          }
        }
      }
    }
  }

  .content {
    box-sizing: border-box;
    height: calc(100vh - 68px);
    padding: 12px !important;
    overflow-y: auto;
    overflow-x: hidden;
    //background-color: #fff;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
