@import '../../../style/var';

.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .login-bg {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/user/loginBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 100px;
    height: 100%;
    flex-shrink: 0;
    flex-direction: column;
    background-color: #5988D8;

    .login-title {
      width: 262px;
      height: 58px;
      margin-bottom: 60px;
      background-image: url('~@/assets/images/user/logo.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .login-form {
      width: 380px;
      height: 370px;
      background: #FFFFFF;
      border-radius: 12px;
      padding: 40px 35px;
      box-sizing: border-box;
      margin-bottom: 60px;

      .form-title {
        font-weight: 500;
        font-size: 22px;
        color: #4288FF;
        text-align: center;
        line-height: 32px;
        margin-bottom: 36px;
      }

      .form-input {
        height: 44px;
        margin-bottom: 20px;

        :global(.ant-input) {
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: rgba(11, 33, 69, 0.25);
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      :global(.form-icon) {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(11, 33, 69, 0.25);
      }

      .form-check {
        font-size: 14px;
        margin-bottom: 20px;
        color: #4288FF;

        span {
          padding-right: 0;

        }
      }

      .protocol {
        color: #4288FF;
        cursor: pointer;
      }

      .submit-btn {
        height: 46px;
        background-image: linear-gradient(227deg, #83DDF8 0%, #4C83EE 90%);
        border-radius: 6px;
        border-color: inherit;
        color: #FFFFFF;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .copyright {
      width: 340px;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      line-height: 22px;
    }
  }
}