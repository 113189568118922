@import '@/style/mixin.less';

.speech-side {
  width: 210px;
  height: calc(100vh - 65px);
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 #0000001a;

  .side-header {
    padding: 16px 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 4px;
      background: #4288FF;
      position: absolute;
      left: 0;
      top: 16px;
      bottom: 16px;
    }

    .side {
      background-image: linear-gradient(227deg, #83ddf8 0%, #4c83ee 90%);
      border-radius: 0 100px 100px 0;
      font-weight: 500;
      font-size: 14px;
      flex-shrink: 0;
      .addFontSize(14px);
      color: #ffffff;
      padding: 10px 42px 10px 18px;
    }
  }

  .side-container {
    padding-left: 24px;
    // padding-bottom: 16px;
    height: calc(100% - 78px);
    overflow-y: auto;

    .scroll-bar();

    .side-step {
      .step-title {
        min-height: 22px;
        font-size: 14px;
        .addFontSize(14px);
        color: #303030;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 6px;
        }

        .title-count {
          background: #4288ff29;
          border-radius: 4px;
          font-size: 14px;
          .addFontSize(14px);
          color: #4288FF;
          padding: 4px;
          line-height: 14px;
          margin-left: 8px;
        }
      }

      .step-title-active {
        font-weight: 600;
        font-size: 14px;
        .addFontSize(14px);
        color: #303030;
      }

      .container {
        padding: 10px 0 10px 14px;
        margin-left: 4px;
        margin-bottom: 6px;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
          position: absolute;
          left: 0;
          top: 0;
        }

        .step-item {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 0;
            background-color: #4288FF;
            position: absolute;
            left: -14px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.5s;
          }
        }

        .step-item-active {
          &::before {
            height: 100%;
          }
        }

        .step {
          max-width: 150px;
          font-size: 14px;
          .addFontSize(14px);
          color: #303030;
          padding: 4px 12px;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          cursor: pointer;
          position: relative;
        }

        .step-active {
          background: #4288ff29;
          font-weight: 500;
          font-size: 14px;
          .addFontSize(14px);
          color: #303030;
          border-radius: 4px;
        }

        .step-ct {
          background: #ff944229;
          border-radius: 4px;
          font-size: 14px;
          .addFontSize(14px);
          color: #ff8142;
          padding: 2px 4px;
          margin-left: 5px;
        }
      }
    }
  }
}